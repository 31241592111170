<template>
  <!-- 待考试 -->
  <div class="changePassword">
    <van-nav-bar
      :title="$t('changePassword.psdTxt1')"
      left-text
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="oldPassword"
          type="password"
          name="oldPassword"
          :label="$t('changePassword.psdTxt2')"
          :placeholder="$t('changePassword.psdTxt2')"
          :rules="[{ required: true, message: $t('changePassword.psdTxt3') }]"
        />
        <van-field
          v-model="newPassword1"
          type="password"
          name="newPassword1"
          :label="$t('changePassword.psdTxt4')"
          :placeholder="$t('changePassword.psdTxt4')"
          :rules="[{ required: true, message: $t('changePassword.psdTxt5') }]"
        />
        <van-field
          v-model="newPassword2"
          type="password"
          name="newPassword2"
          :label="$t('changePassword.psdTxt6')"
          :placeholder="$t('changePassword.psdTxt6')"
          :rules="[{ required: true, message: $t('changePassword.psdTxt7') }]"
        />
        <!-- <p class="pswDesc">
          密码必须是8-16位的英文字母、数字、字符组合（不能 是纯数字）
        </p>-->
        <div style="margin: 16px;">
          <van-button
            round
            block
            type="info"
            :disabled="!canSave"
            native-type="submit"
          >{{$t('module.submit')}}</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { changeUserPwd } from '@api/wx.js'
export default {
  data() {
    return {
      userInfo: JSON.parse(localStorage.userInfo) || {},
      oldPassword: '',
      newPassword1: '',
      newPassword2: ''
    }
  },
  computed: {
    canSave() {
      if (
        this.newPassword1 &&
        this.newPassword2 &&
        this.newPassword1 == this.newPassword2
      ) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    onClickLeft() {
      this.$router.push('/personCen')
    },
    onClickRight() {},
    // 提交
    onSubmit(values) {
      const data = {
        username: this.userInfo.username,
        oldpwd: values.oldPassword,
        newpwd1: values.newPassword1,
        newpwd2: values.newPassword2
      }
      changeUserPwd(data).then(res => {
        if (res.iserror === '0') {
          // 修改缓存密码
          localStorage.setItem('v8_login_pwd', values.newPassword1),
            // Toast.success(this.$t('changePassword.psdTxt8'))
            Dialog.alert({
              message: this.$t('changePassword.psdTxt8')
            }).then(() => {
              // on close
              this.onClickLeft()
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.changePassword {
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .van-form {
    .pswDesc {
      padding: 20px 30px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 40px;
      color: #333333;
    }
  }
}
</style>